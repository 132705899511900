// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  /*optional bg color*/
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  /*the following isn't necessary but will help center everything once we add buttons*/
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 .lightbox-img{
    width: 80% !important;
    height: 80% !important;
    margin: auto;
  object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/components/fileSelector/fileSelector.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;EACf,MAAM;EACN,OAAO;EACP,sBAAsB;EACtB,uBAAuB;EACvB,oBAAoB;EACpB,4BAA4B,EAAE,mBAAmB;EACjD,iCAAiC,EAAE,qBAAqB;EACxD,oFAAoF;EACpF,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;CACC;IACG,qBAAqB;IACrB,sBAAsB;IACtB,YAAY;EACd,mBAAmB;AACrB","sourcesContent":["#lightbox {\n  z-index: 1;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100% !important;\n  height: 100% !important;\n  /*optional bg color*/\n  background-color: rgb(0,0,0); /* Fallback color */\n  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */\n  /*the following isn't necessary but will help center everything once we add buttons*/\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n .lightbox-img{\n    width: 80% !important;\n    height: 80% !important;\n    margin: auto;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
