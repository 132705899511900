import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Message from "../../constants/Message"

const Signup = () => {
  const [formValue, setFormValue] = useState();
  const [errors, setError] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  var baseUrl = process.env.REACT_APP_DEV_BASE_URL


  useEffect(() => {
    console.log("form Values are " + JSON.stringify(formValue));
    console.log("email  ", `/signup-verification/${formValue?.email}`)
  }, [formValue]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  var sendOtp = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const payload = {
        ...formValue, "role_code": "USER"
      };
      console.log(payload);
      var response = await fetch(
        `${baseUrl}/modules/users/signup`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      var otpResponse = await response.json();
      console.log(otpResponse);
      if (otpResponse.success) {
        navigate(`/signup-verification/${formValue?.email}`)
      }
      else if (otpResponse.errors.length > 0) {
        setError(otpResponse.errors)
      }
    }
    catch (error) {
      console.log("Error " + error)
    }
    finally {
      setLoading(false)
    }

  };

  return (
    <form className="formWrapper  w-100  m-auto" onSubmit={sendOtp}>
      <div className="mb-2">
        <h3 className="text-center title">Sign Up</h3>
        <label htmlFor="first_name">Name</label>
        <input
          type="text"
          className="form-control"
          name="first_name"
          id=""
          onChange={handleInputChange}
          aria-describedby="helpId"
        />
        <Message type={'error'} errors={errors} fieldName={'first_name'} />
      </div>
      <div className="mb-2">
        <label>Enter Your Email</label>
        <input
          type="email"
          className="form-control"
          name="email"
          id=""
          onChange={handleInputChange}
          aria-describedby="helpId"
        />
        <Message type={'error'} errors={errors} fieldName={'email'} />
      </div>
      <div className="mb-2">
        <label>Enter Your Phone Number</label>
        <input
          type="number"
          className="form-control"
          name="phone"
          id=""
          onChange={handleInputChange}
          aria-describedby="helpId"
        />
        <Message type={'error'} errors={errors} fieldName={'phone'} />
      </div>
      <div className="text-center">
        {loading && <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>}
      </div>
      <div className="d-grid gap-2">
        <button
          type="submit"
          name=""
          id=""
          className="btn btn-primary bg_button"
        >
          Submit
        </button>
      </div>

    </form>
  );
};

export default Signup;
