// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination .page-link {
    padding: 10px 15px !important;
    border-radius: 5px;  
}


li.page-item.active .page-link {
    border: 1px solid #9a2c23;
    background-color: #9a2c23;
    color: #fff !important;
}

.pagination {
    justify-content: center;
    gap: 10px;
}

li.page-item.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}
.page-item .page-link{
    color: #9a2c23 !important;
}
.page-item .page-link:focus{
    outline: none;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/pagination/pagination.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;AACtB;;;AAGA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,aAAa;EACf,gBAAgB;AAClB","sourcesContent":[".pagination .page-link {\n    padding: 10px 15px !important;\n    border-radius: 5px;  \n}\n\n\nli.page-item.active .page-link {\n    border: 1px solid #9a2c23;\n    background-color: #9a2c23;\n    color: #fff !important;\n}\n\n.pagination {\n    justify-content: center;\n    gap: 10px;\n}\n\nli.page-item.disabled {\n    cursor: not-allowed;\n    pointer-events: none;\n    opacity: 0.5;\n}\n.page-item .page-link{\n    color: #9a2c23 !important;\n}\n.page-item .page-link:focus{\n    outline: none;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
