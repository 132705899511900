import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import RegisteredList from "../../components/registration/RegisteredList"
import '../../components/registration/registeredList.css'
import { useUser } from "../../utils/utils"
import Navbar from "../../components/header/Navbar"
import { getTokenDetails } from "../../utils/tokenUtils"


const RegisteredListPage = () => {
  const { user, setUser } = useUser()
  const navigate = useNavigate()
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const tokenDetails = getTokenDetails(user?.token);
    if (tokenDetails?.role_code == 'ADMIN') {
      setIsAdmin(true);
    }
  }, [user]);


  return (
    <>
      <Navbar />
      <div className="container">
        <div className="registeredListPage row justify-content-center align-items-center mt-3 g-2">
          <div className="col">
            <h2 className="registeredListTitle title">Registered List</h2>
            {/* <span style={{ fontSize: "8px" }}>Token - {user?.token}</span> */}
          </div>
          <div className="col d-flex justify-content-end">
            {
              !isAdmin && (
                <button
                  type="button"
                  className="btn btn-primary bg_button"
                  onClick={() => navigate("/registration/add")}
                >
                  Apply
                </button>
              )
            }
          </div>
        </div>
        <RegisteredList />
      </div>
    </>
  );
};

export default RegisteredListPage;
