import React, { useEffect, useState } from "react";
import { useAuth } from "../../hook/AuthProvider";
import { useNavigate } from "react-router-dom";
import "./registeredList.css";
import { useLocation } from 'react-router-dom'
import { useUser } from "../../utils/utils"
import Pagination from "../pagination/Pagination";
import { getTokenDetails } from "../../utils/tokenUtils";


const RegisteredList = () => {
  const { user, setUser } = useUser();
  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const tokenDetails = getTokenDetails(user?.token);
    if (tokenDetails?.role_code == 'ADMIN') {
      setIsAdmin(true);
    }
  }, [user]);


  useEffect(() => {
    console.log("Inside registered list page")
    const tokenFromUrl = new URLSearchParams(location.search).get('token')
    console.log("tokenfromAPP", tokenFromUrl);

    if (tokenFromUrl) {
      console.log("Token from URL: " + tokenFromUrl)
      localStorage.setItem('user', JSON.stringify({ token: tokenFromUrl }))
      setUser({ token: tokenFromUrl })
    }
  }, [])

  useEffect(() => {
    getCandidateList();
  }, [user, currentPage]);

  const getCandidateList = async () => {
    setLoading(true);
    try {
      const getCandidateListData = await fetch(`${baseUrl}/modules/candidates/apply/list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: user?.token
          // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMTMiLCJpYXQiOjE3MTk0MDUwODIsImV4cCI6MTcyMTk5NzA4Mn0.kituNf_d-LrZ-WDZBUuRbWbgNmyJgWUJlzTNgfzKit4",
        },
        body: JSON.stringify({
          current_page: currentPage,
        }),
      })
      const response = await getCandidateListData.json()
      console.log("response", response);
      setRegistrations(response.records)
      const totalPages = response.total_pages
      setTotalPage(Array.from({ length: totalPages }, (_, i) => i + 1));
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    };
  };



  const deleteCandidate = async (candidate_id) => {
    console.log(candidate_id);
    setLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}/modules/candidates/apply/delete/${candidate_id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token
            // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMTMiLCJpYXQiOjE3MTk0MDUwODIsImV4cCI6MTcyMTk5NzA4Mn0.kituNf_d-LrZ-WDZBUuRbWbgNmyJgWUJlzTNgfzKit4"
          },
          body: JSON.stringify({}),
        }
      );
      var deleteCandidateResponse = await response.json();
      console.log("response is" + JSON.stringify(deleteCandidateResponse));
      if (deleteCandidateResponse.success) {
        getCandidateList();
      }
    } catch (error) {
      console.log("Error" + error);
    } finally {
      setLoading(false);
    }
  };
  // console.log("registrations", registrations.users);


  return (
    <>
      {loading ? (
        <div className="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden ">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <thead className="">
              <tr className="label">
                <th>Name</th>
                <th>Status</th>
                {
                  !isAdmin && (
                    <th>Action</th>
                  )
                }

              </tr>
            </thead>
            <tbody className="">
              {registrations?.map((registration, index) => (
                <tr key={registration.id} className="">
                  <td>
                    <div className="mt-3">
                      <span className="registered_list" onClick={() =>
                        navigate(
                          `/registration/edit/${registration.candidate_id}`
                        )
                      }>
                        {registration.first_name} {registration.last_name}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="mt-3">
                      {registration.status === 'STS_DRAFT' ? (
                        <span className="badge bg-warning">DRAFT</span>
                      ) : (
                        <span className="badge bg-success">SUBMITTED</span>
                      )}
                    </div>
                  </td>
                  {!isAdmin && (
                    <td>
                      <div className="deleteButton">
                        <button
                          type="button"
                          className="btn-change"
                          onClick={() =>
                            deleteCandidate(registration.candidate_id)
                          }
                        >
                          <svg width={"15px"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#ff0000" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg>
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="text-center">
                  {registrations && totalPage?.length > 1 ? (
                    <Pagination
                      totalPage={totalPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegisteredList;
