import "./App.css";
import './responsive.css'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import AuthProvider from "./hook/AuthProvider";
import PrivateRoute from "./router/route";
import { LoginPage } from "./pages/auth/LoginPage";
import { SignupPage } from "./pages/auth/SignupPage";
import { RegistrationEditPage } from "./pages/registration/RegistrationEditPage";
import { RegistrationPage } from "./pages/registration/RegistrationPage";
import RegisteredListPage from "./pages/registration/RegisteredListPage";
import { LoginOtpPage } from "./pages/auth/LoginOtpPage";
import { SignupOtpPage } from "./pages/auth/SignupOtpPage";
import { useState } from "react";
import { UserContext } from "./context/UserContext";
import { ForgotPasswordPage } from "./pages/auth/ForgotPasswordPage";


function App() {
  const [user, setUser] = useState();
  return (
    <>
      <Router>
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          {/* <AuthProvider> */}
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/login-verification" element={<LoginOtpPage />} />
              <Route path="/signup-verification/:email" element={<SignupOtpPage />} />
              <Route path="/changepassword-verification/:email" element={<SignupOtpPage />} />
              {/* <Route  element={<PrivateRoute />} > */}
                <Route path="/registered-list" element={<RegisteredListPage />} />
                <Route path="/registration/add" element={<RegistrationPage />} />
                <Route path="/registration/edit/:candidate_id" element={<RegistrationEditPage />} />
              {/* </Route> */}
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
            </Routes>
          {/* </AuthProvider> */}
        </UserContext.Provider>

      </Router>
    </>
  );
}

export default App;
