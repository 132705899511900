import React from 'react'
import bvkAwardImage from "./../../images/bvk_logo.jpg";

const Header = () => {
    return (
        <div>
            <div className="text-center">
                <img src={bvkAwardImage} className="login-logo img-fluid  " alt="" />
            </div>
            <h3 className="pt-4 pb-3 title fw-bold">BVK AWARDS</h3>
            <h4 className="title fw-bold">SOURASHTRIAN GLOBAL ACHIEVERS AWARDS</h4>
        </div>
    )
}

export default Header
