export const sub_category = {

    AWD_STUDENT: [
        {
            label: "University Level Achiever",
            value: "AWD_SUB_CAT_UNIV_LEVEL_ACHVR"
        },
        {
            label: "National Level Achiever",
            value: "AWD_SUB_CAT_NAT_LEVEL_ACHVR"
        },
        {

            label: "International/ UN agencies Recognition",
            value: "AWD_SUB_CAT_INT_UN_RECOG"
        },
        {
            label: "Patents",
            value: "AWD_SUB_CAT_PATENTS"

        },
        {
            label: "Others/ Multiple levels",
            value: "AWD_SUB_CAT_OTHER_STUDENT"
        }
    ],
    AWD_YOUTH: [
        {
            label: "Science, Tech, Engineering & Health Care",
            value: "AWD_SUB_CAT_SCIENCE_TECH"
        },
        {
            label: "Industry, Business, Innovation & Enterprise",
            value: "AWD_SUB_CAT_IND_BUS"
        },
        {
            label: "Arts & Culture",
            value: "AWD_SUB_CAT_ARTS"
        },
        {
            label: "Social Service, Environment, Climate Goals",
            value: "AWD_SUB_CAT_SOCIAL"
        },
        {
            label: "Sports",
            value: "AWD_SUB_CAT_YOUTH_SPORTS"
        },
        {
            label: "Others / Multiple areas",
            value: "AWD_SUB_CAT_OTHER_YOUTH"
        },
    ],
    AWD_PROM_YOUTH: [
        {
            label: "Education",
            value: "AWD_SUB_CAT_PROMOTION_EDUCATION"
        },
        {
            label: "Employment",
            value: "AWD_SUB_CAT_PROMOTION_EMPLOYMENT"
        },
        {
            label: "Healthcare",
            value: "AWD_SUB_CAT_PROMOTION_HEALTHCARE"
        },
        {
            label: "Sports",
            value: "AWD_SUB_CAT_PROMOTION_SPORTS"
        },
        {
            label: "Others/ Multiple areas",
            value: "AWD_SUB_CAT_PROMOTION_OTHERS"
        },
    ]


}