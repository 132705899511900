import React from "react";
import "../../components/registration/registration.css";
import bvkAwardImage from "./../../images/bvk_logo.jpg";
import Registration from "../../components/registration/Registration";
import Header from "../../components/header/Header";

export const RegistrationPage = () => {
  return (
    <div className="container-fluid registrationContainer d-flex justify-content-center">
      <section className="awardRegistrationForm">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading">
              <Header/>
              <Registration />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
