import React from 'react'
import bvkAwardImage from "../../images/bvk_logo.jpg";
import { LoginOTP } from '../../components/otp/LoginOTP';
export const LoginOtpPage = () => {
  return (
    <div className="container-fluid ">
      <div className="login-row row">
        <div className="login-wrapper col-lg-12">
          <img src={bvkAwardImage} className="login-logo img-fluid " alt="" />
          <div className="form_wrapper">
            <LoginOTP />
          </div>
        </div>
      </div>
    </div>
  )
}
