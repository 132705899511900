import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Message from "../../constants/Message";
import ImageSelector from "../imageSelector/ImageSelector";
import { CountryCode } from "../../constants/countryCode";
import PdfSelector from "../fileSelector/FileSelector";
import { useUser } from "../../utils/utils";
import { sub_category } from '../../constants/category'

const Registration = () => {
  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ address: {} });
  const [errors, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [passport_image, setPassportImage] = useState(null);
  const [idProof, setIdProof] = useState(null)
  const [contryList, setCountryList] = useState([]);
  const [document1, setDocument1] = useState(null)
  const [document2, setDocument2] = useState(null)
  const [document3, setDocument3] = useState(null)
  const [document4, setDocument4] = useState(null)
  const [document5, setDocument5] = useState(null)
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [textErrors, setTextErrors] = useState({
    profile: "",
    achievements: "",
    declaration: ''
  });
  const [isChecked, setIsChecked] = useState(false);
  const { user, setUser } = useUser()


  // console.log("idProof", idProof?.document_id);

  const handleLimitChange = (e) => {
    const { name, value } = e.target;
    const wordCount = value.trim().split(/\s+/).filter(word => word.length > 0).length;

    if (name === "profile_description" && wordCount >= 250) {
      setTextErrors((prevState) => ({
        ...prevState,
        profile: "Profile description cannot exceed 250 words.",
      }));
    } else if (name === "achievements_description" && wordCount >= 500) {
      setTextErrors((prevState) => ({
        ...prevState,
        achievements: "Achievements description cannot exceed 500 words.",
      }));
    } else {
      setFormValues({ ...formValues, [name]: value });
      setTextErrors({
        profile: "",
        achievements: "",
      });
    }
  };



  useEffect(() => {
    console.log(formValues);
    getCountryList();
  }, [formValues]);

  // console.log("full", uploadDocument);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "category") {
      setSubCategoryOptions(sub_category[value] || []);
      setFormValues({ ...formValues, [name]: value, sub_category: "" });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };


  const handleAddressChange = (e) => {
    var modified_address = formValues.address;
    modified_address[e.target.name] = e.target.value;
    setFormValues({ ...formValues, ...{ address: modified_address } });
  };

  const onChangePassportImage = (newlySelectedImage) => {
    setPassportImage(newlySelectedImage);
  };

  const getCountryList = async () => {
    try {
      const countryResponse = await fetch(
        `${baseUrl}/modules/countries/get-countries`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token
            // token:  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNTksImlhdCI6MTcxODAyNjg4NywiZXhwIjoxNzIwNjE4ODg3fQ.yKxJXTbPHU-SurNmdPTNz8hkSc_peEmrSVGH0zBVwiU",
          },
        }
      );
      const countryListResponse = await countryResponse.json();
      setCountryList(countryListResponse.records);
    } catch (error) {
      console.log("Error" + error);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const registerCandidate = async (e, status) => {
    e.preventDefault();
    if (!isChecked) {
      setTextErrors((prevState) => ({
        ...prevState,
        declaration: "Please confirm the declaration.",
      }));
      return;
    }
    else {
      setIsLoading(true);
      // console.log("token",user?.token);

      const payload = {
        ...formValues,
        "is_active": true,
        passport_image: passport_image,
        id_proof: idProof?.document_id,
        document_1: document1?.document_id,
        document_2: document2?.document_id,
        document_3: document3?.document_id,
        document_4: document4?.document_id,
        document_5: document5?.document_id,
        status: status
      };
      try {
        const response = await fetch(
          `${baseUrl}/modules/candidates/apply/register`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              token: user?.token
              // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMTMiLCJpYXQiOjE3MjA0MjY2MzUsImV4cCI6MTcyMzAxODYzNX0.6fp8BV5tlGRYP0Om9uMmkiEyEv-24vTjDzJH2MKgoWg",
            },

            body: JSON.stringify(payload),
          }
        );
        console.log(payload);
        var registrationResponse = await response.json();
        console.log("response", registrationResponse);
        if (registrationResponse.success) {
          navigate("/registered-list");
        } else if (registrationResponse.errors?.length > 0) {
          setError(registrationResponse.errors);
          console.log(registrationResponse.errors);
        }
      } catch (error) {
        console.log("Error " + error);
      } finally {
        setIsLoading(false);
      }
      setTextErrors((prevState) => ({
        ...prevState,
        declaration: "",
      }));
    }
  };


  return (
    <div>
      <div className="my-3">
        <button onClick={() => navigate('/registered-list')} className="btn btn-primary bg_button">Back</button>
      </div>
      <div className="">
        <label>
          Are you the applicant or nominating another person?{" "}
          <sup className="starOne">*</sup>
        </label>
        <select
          className="form-control mt-1"
          name="candidate_type"
          value={formValues?.candidate_type || ""}
          onChange={handleInputChange}
        >
          <option value="-- Select--">-- Select --</option>
          <option value="CAN_APPLICANT">Applicant</option>
          <option value="CAN_NOMINEE">Nominating another person</option>
        </select>
        <label className="mt-2">
          If you are nominating somebody, please provide the details of the
          nominee and not your details.
        </label>
      </div>
      <div className="registrationDiv">
        <div>
          <label className="">Award Category <sup className="star">*</sup></label>
          <select
            className="form-control mt-1"
            name="category"
            value={formValues?.category || ""}
            onChange={handleInputChange}
          >
            <option value="" aria-readonly>
              -- Select Award Category --
            </option>
            <option value="AWD_STUDENT">Student Achievers Award</option>
            <option value="AWD_YOUTH">Youth Achievers Award</option>
            <option value="AWD_PROM_YOUTH">Promotion of Youth Services Award</option>
          </select>
          <Message type={"error"} errors={errors} fieldName={"category"} />
        </div>
        <div>
          <label className="">Award Sub Category <sup className="star">*</sup></label>
          <select
            className="form-control mt-1"
            name="sub_category"
            value={formValues?.sub_category || ""}
            onChange={handleInputChange}
          >
            <option value="" aria-readonly>
              -- Select Sub Award Category --
            </option>
            {subCategoryOptions?.map((subCat) => (
              <option key={subCat.value} value={subCat.value}>
                {subCat.label}
              </option>
            ))}
          </select>
          <Message type={"error"} errors={errors} fieldName={"sub_category"} />
        </div>

        <div className="card pt-3">
          <div className="card-body">
            <form>
              <div className="form-group d-flex justify-content-between">
                {/* First Name */}
                <div className="firstName">
                  <label htmlFor="first_Name">
                    First Name <sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    className="form-control"
                    name="first_name"
                    value={formValues?.first_name || ""}
                    onChange={handleInputChange}
                  />
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"first_name"}
                  />
                </div>

                {/* Last Name */}
                <div className="lastName">
                  <label htmlFor="last_Name">Last Name </label>
                  <input
                    type="text"
                    id="last_name"
                    className="form-control"
                    name="last_name"
                    value={formValues?.last_name || ""}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="family_name">
                  <label htmlFor="fullName">
                    Gheru Naav (Sourashtra Family Name)<sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="family_name"
                    value={formValues?.family_name || ""}
                    onChange={handleInputChange}
                  />
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"family_name"}
                  />
                </div>
              </div>

              <div className="form-group d-flex justify-content-between">
                {/* GENDER Type */}
                <div className="gender">
                  <label htmlFor="gender">
                    Gender<sup className="star">*</sup>
                  </label>
                  <select
                    className="form-control"
                    name="gender"
                    value={formValues?.gender || ""}
                    onChange={handleInputChange}
                    required
                  >
                    <option value=" ">-- Select Gender --</option>
                    <option value="GEN_MALE">Male</option>
                    <option value="GEN_FEMALE">Female</option>
                    <option value="GEN_OTHER">Others</option>
                  </select>
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"gender"}
                  />
                </div>
                {/* Date Of Birth */}
                <div className="dob">
                  <label htmlFor="dob">
                    Date Of Birth<sup className="star">*</sup>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="date_of_birth"
                    value={formValues?.date_of_birth || ""}
                    onChange={handleInputChange}
                    required
                  />
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"date_of_birth"}
                  />
                </div>
              </div>

              <div className="form-group d-flex justify-content-between">
                {/* Profession Type */}
                <div className="profeesionType">
                  <label htmlFor="Status">
                    Profession Type<sup className="star">*</sup>
                  </label>
                  <select
                    className="form-control"
                    name="profession_type"
                    value={formValues?.profession_type || ""}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">-- Select Profession Type --</option>
                    <option value="PROF_STUDENT">Student</option>
                    <option value="PROF_EMPLOYEE">Employee</option>
                    <option value="PROF_PROFESSIONAL">Professional</option>
                    <option value="PROF_SELF_EMPLOYED">Self Employed</option>
                  </select>
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"profession_type"}
                  />
                </div>

                <div className="current_occupation">
                  <label htmlFor="fullName">
                    Current Occupation<sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="current_occupation"
                    value={formValues?.current_occupation || ""}
                    onChange={handleInputChange}
                    required
                  />
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"current_occupation"}
                  />
                </div>
              </div>

              <div className="form-group d-flex justify-content-between">
                {/* Marital status*/}
                <div className="maritalStatus">
                  <label htmlFor="maritalStatus">
                    Marital Status<sup className="star">*</sup>
                  </label>
                  <select
                    className="form-control"
                    name="marital_status"
                    value={formValues?.marital_status || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">-- Select Marital Status --</option>
                    <option value="MS_SINGLE">Single</option>
                    <option value="MS_MARRIED">Married</option>
                    <option value="MS_DIVORCED">Divorced</option>
                  </select>
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"marital_status"}
                  />
                </div>

                <div className="spouseName">
                  <label htmlFor="fullName">Spouse Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="spouse_name"
                    value={formValues?.spouse_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                {/* Email */}
                <label htmlFor="email">
                  Candidate's Email<sup className="star">*</sup>
                </label>
                <input
                  type="email"
                  className="form-control"
                  onChange={handleInputChange}
                  name="candidate_email"
                  value={formValues?.candidate_email || ""}
                  required
                />
                <Message
                  type={"error"}
                  errors={errors}
                  fieldName={"candidate_email"}
                />
              </div>
              <div className="mobileNumberDiv form-group">
                {/* Mobile Number */}
                <label htmlFor="phone">
                  Candidate's Phone<sup className="star">*</sup>
                </label>
                <div className="mobileNumber d-flex">
                  <select
                    id="country-codes"
                    className="countryCode form-control"
                    onChange={handleInputChange}
                    name="candidate_phone_code"
                    value={formValues?.candidate_phone_code || ''}
                  >
                    {CountryCode.map((code) => (
                      <option key={code.dial_code} value={code.dial_code}>
                        {code.name} {code.dial_code}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    className="mobileInput form-control"
                    name="candidate_phone"
                    value={formValues?.candidate_phone || ""}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <Message type={"error"} errors={errors} fieldName={"phone"} />
              </div>
              <div className="form-group d-flex flex-column">
                {/* Address Two */}
                <div className="w-100">
                  <label htmlFor="address">
                    Address Line 1<sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="line_1"
                    value={formValues?.address?.line_1 || ""}
                    onChange={handleAddressChange}
                    required
                  />
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"line_1"}
                  />
                </div>

                {/* Village */}
                <div className="w-100">
                  <label htmlFor="address">Address Line 2</label>
                  <input
                    type="text"
                    className="form-control"
                    name="line_2"
                    value={formValues?.address?.line_2}
                    onChange={handleAddressChange}
                    required
                  />
                </div>

                {/* City */}
                <div className="w-100">
                  <label htmlFor="address">
                    City<sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={formValues?.address?.city || ""}
                    onChange={handleAddressChange}
                    required
                  />
                  <Message type={"error"} errors={errors} fieldName={"city"} />
                </div>

                {/* State */}
                <div className="w-100">
                  <label htmlFor="address">
                    State <sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    value={formValues?.address?.state || ""}
                    onChange={handleAddressChange}
                    required
                  />
                  <Message type={"error"} errors={errors} fieldName={"state"} />
                </div>

                {/* Pincode */}
                <div className="zipcode">
                  <label htmlFor="pincode">
                    Zip or Postal Code <sup className="star">*</sup>
                  </label>
                  <input
                    className="form-control"
                    name="zipcode"
                    value={formValues?.address?.zipcode || ""}
                    onChange={handleAddressChange}
                    required
                  />
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"zipcode"}
                  />
                </div>
                {/* Nationality */}
                <div className="w-100">
                  <label htmlFor="address">
                    Country<sup className="star">*</sup>
                  </label>
                  <div className="d-flex">
                    <select
                      id="country-codes"
                      className="form-control"
                      onChange={handleAddressChange}
                      name="country"
                      value={formValues?.address?.country || ''}
                    >
                      {contryList?.map((country) => (
                        <option value={country.country_code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"country"}
                  />
                </div>
                <div className="form-group">
                  <ImageSelector
                    onChangeImage={onChangePassportImage}
                  />
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"passport_image"}
                  />
                </div>
                <div className="form-group">
                  <PdfSelector
                    fieldName={'id_proof'}
                    id={'Aadhar, License, Voter ID, Passport , Student ID, Company ID. any National ID'}
                    document={idProof}
                    setDocument={setIdProof} />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card">
          {/* Family Details */}
          <div className="card-header mb-4">
            <h2 className="title">FAMILY DETAILS</h2>
          </div>
          <div className="form-group d-flex justify-content-between">
            {/* Father Name */}
            <div className="fName">
              <label htmlFor="fatherName">
                Father's Name<sup className="star">*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                name="father_name"
                value={formValues?.father_name || ""}
                onChange={handleInputChange}
                required
              />
              <Message fieldName={'father_name'} errors={errors} type={'error'} />
            </div>

            <div className="mName">
              <label htmlFor="motherName">
                Mother's Name<sup className="star">*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                name="mother_name"
                value={formValues?.mother_name || ""}
                onChange={handleInputChange}
                required
              />
              <Message fieldName={'father_name'} errors={errors} type={'error'} />
            </div>
          </div>
        </div>

        <div className="card">
          <div className="form-group">
            <label htmlFor="achievements"></label>
            <div className="">
              <label className="pt-2">
                Candidate's Profile In 250 Words<sup className="starOne">*</sup>
              </label>
              <textarea
                className="form-control"
                name="profile_description"
                value={formValues?.profile_description}
                onChange={handleLimitChange}
                rows="4"
              ></textarea>
              <Message
                text={textErrors.profile}
                type={"error"}
                errors={errors}
                fieldName={"profile_description"}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="achievements"></label>
            <div className="">
              <label className="pt-2">
                Candidate's Achievement In 500 Words
                <sup className="starOne">*</sup>
              </label>
              <textarea
                className="form-control"
                name="achievements_description"
                value={formValues?.achievements_description || ""}
                onChange={handleLimitChange}
                rows="4"
              ></textarea>
              <Message
                type={"error"}
                text={textErrors.achievements}
                errors={errors}
                fieldName={"achievements_description"}
              />
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h2 className="title">Document Evidence</h2>
          </div>
          <div className="form-group">
            <label htmlFor="" className="py-4">
              Please upload marksheets, certificates, paper cuttings, magazine
              excerpts or any other documents to showcase your achievements
            </label>

            <div className="form-group">
              <PdfSelector
                id={"Document 1"}
                document={document1}
                setDocument={setDocument1}
              />
              <PdfSelector
                id={"Document 2"}
                document={document2}
                setDocument={setDocument2}
              />
              <PdfSelector
                id={"Document 3"}
                document={document3}
                setDocument={setDocument3}
              />
              <PdfSelector
                id={"Document 4"}
                document={document4}
                setDocument={setDocument4}
              />
              <PdfSelector
                id={"Document 5"}
                document={document5}
                setDocument={setDocument5}
              />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="form-group">
            <div className="card-header mb-3">
              <h2 className="title">Declaration</h2>
            </div>
            <div className="d-flex align-items-start gap-2">
              <input type="checkbox" className="mt-1" id="declarationCheckbox" onChange={handleCheckboxChange} />
              <label className="">I here by that the information provided herein is true to my knowledge & belief. I shall abide the decision of the Jury as final.</label>
            </div>
            <br />
            <Message type={'error'} text={textErrors.declaration} />
            {
              errors?.length >= 1 ? <p className="text-danger errorSize fw-bold">Please correct errors and submit</p> : null
            }
          </div>

        </div>
        <div className="text-center">
          {isLoading && (
            <div className="loader spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>

        <div className="registrationButtonCard card px-2">
          {/* Save Button */}
          <button
            type="submit"

            onClick={(e) => registerCandidate(e, 'STS_DRAFT')}
            className="darftBtn"
          >
            Save for Future Editing
          </button>
          <button
            type="submit"

            onClick={(e) => registerCandidate(e, 'STS_SUBMIT')}
            className="darftBtn"
          >
            Submit for Processing
          </button>

          {/* <div className="loaderfooterDiv">
            <div className="loaderFooter">
              {isLoading && (
                <div className="loader spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Registration;
