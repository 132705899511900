import React from "react";
import Login from "../../components/login/Login";
import bvkAwardImage from "./../../images/bvk_logo.jpg";
import LoginLogo from '../../images/login_logo.png'
import "../../components/login/LoginPage.css";
export const LoginPage = () => {
  return (
    <div className="container-fluid ">

      <div className="row align-items-center">
        <div className="col-lg-6 col-md-6 text-center">
          <img src={LoginLogo} className="w-50 img-fluid bvk_logo" alt="Login Logo" />
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="w-75 m-auto">
            <div className="text-center mb-3">
              <img src={bvkAwardImage} className="login-logo img-fluid " alt="" />
            </div>
            <h3 className="text-center title">Log In</h3>
            <Login />
          </div>
        </div>
      </div>
    </div>
  );
};
