import React from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { useUser } from "../utils/utils";
import { useAuth } from "../hook/AuthProvider";


const PrivateRoute = () => {
  const user = useAuth();
  // const { user } = useUser()
  // const storedUser = localStorage.getItem('user');
  // if (!user?.token && !storedUser) {
  if (!user.token) return <Navigate to="/" />;
    return <Outlet />;
  
  // return <Outlet />;
};

export default PrivateRoute;