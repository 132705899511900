import React from "react";

import bvkAwardImage from "./../../images/bvk_logo.jpg";
import LoginLogo from '../../images/login_logo.png'
import "../../components/login/LoginPage.css";
import ForgotPassword from "../../components/forgot-password/ForgotPassword";
export const ForgotPasswordPage = () => {
  return (
    <div className="container-fluid ">
      <div className="row min-vh-100 justify-content-center align-items-center">
        <div className="col-lg-6 col-md-6 text-center">
          <img src={LoginLogo} className="w-50 img-fluid bvk_logo" alt="Login Logo" />
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="w-75 m-auto">
            <div className="text-center mb-3">
              <img src={bvkAwardImage} className="login-logo img-fluid " alt="" />
            </div>
            <h3 className="text-center title">Forgot Password</h3>
           <ForgotPassword/>
          </div>
        </div>
      </div>
    </div>
  );
};
