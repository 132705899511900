// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width:576px) {
    .registeredListTitle{
        font-size: 19px !important;
    }
    .headerContainer{
        display: none !important;
    }
 
}
@media screen and (max-width:768px) {
    .headerContainer{
        display: none !important;
    }
 
}



`, "",{"version":3,"sources":["webpack://./src/responsive.css"],"names":[],"mappings":"AAAA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,wBAAwB;IAC5B;;AAEJ;AACA;IACI;QACI,wBAAwB;IAC5B;;AAEJ","sourcesContent":["@media screen and (max-width:576px) {\n    .registeredListTitle{\n        font-size: 19px !important;\n    }\n    .headerContainer{\n        display: none !important;\n    }\n \n}\n@media screen and (max-width:768px) {\n    .headerContainer{\n        display: none !important;\n    }\n \n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
