import React, { useEffect, useState } from 'react';
import { useUser } from '../../utils/utils';
import Message from '../../constants/Message';
import './fileSelector.css'

const FileSelector = ({ document, setDocument, id, status, fieldName, candidate_id }) => {
  // console.log("fieldName",fieldName);
  // console.log("candidate_id",candidate_id);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewPdf, setPreviewPdf] = useState(null);
  const [error, setError] = useState('')
  const { user, setUser } = useUser()
  const [lightboxDisplay, setLightBoxDisplay] = useState(false)
  const [imageToShow, setImageToShow] = useState('')

  const handleImageSelect = (event) => {
    const imageToUpload = event.target.files[0];
    validateAndSetFile(imageToUpload);
  };


  const validateAndSetFile = (imageToUpload) => {
    var FILE_SIZE = imageToUpload.size
    var MAX_SIZE = 10000000
    if (imageToUpload) {
      const fileName = imageToUpload.name.toLowerCase();
      if (
        FILE_SIZE <= MAX_SIZE &&
        (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png') || fileName.endsWith('.heic') || fileName.endsWith('.pdf'))
      ) {
        uploadImage(imageToUpload)

        setError('')
      } else {
        console.error("Invalid file format. Please select a JPG, JPEG, PNG, or PDF file.");
        setError('File is too large or not a supported format')
        setDocument(null);
      }
    }
  };

  const uploadImage = async (imageToUpload) => {
    // console.log("usertoken before upload image",user?.token);
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('uploaded_file', imageToUpload);
      const headers = {
        method: "POST",
        token: user?.token
        // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNTksImlhdCI6MTcxODAyNjg4NywiZXhwIjoxNzIwNjE4ODg3fQ.yKxJXTbPHU-SurNmdPTNz8hkSc_peEmrSVGH0zBVwiU",
      };
      const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
      const response = await fetch(`${baseUrl}/modules/documents/upload`, {
        method: "POST",
        headers: headers,
        body: formData,
      });
      if (response.ok) {
        const uploadedDocument = await response.json();
        console.log("Upload Successful:", JSON.stringify(uploadedDocument));
        setDocument(uploadedDocument)

      } else {
        console.error("Upload Failed:", response.statusText);
        setDocument(null)
      }
    } catch (error) {
      console.error("Upload Failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleToShow = (e, document) => {
    e.preventDefault();
    if (previewImage === document.path || previewPdf === document.path) {
      setPreviewImage(null);
      setPreviewPdf(null);
    } else {
      const fileName = document.name.toLowerCase();
      if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png') || fileName.endsWith('.heic')) {
        setPreviewImage(document.path);
        setPreviewPdf(null);
      } else if (fileName.endsWith('.pdf')) {
        setPreviewPdf(document.path);
        setPreviewImage(null);
      }
    }

  };

  const showImage = (image) => {
    //set imageToShow to be the one that's been clicked on    
    setImageToShow(image);
    //set lightbox visibility to true
    setLightBoxDisplay(true);
  };

  const handleDocumentDelete = async (document, setDocument) => {
    console.log(document.document_id);
    var delete_id = document.document_id

    try {
      var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
      var pay_load = {
        "candidate_id": candidate_id,
        "candidate_field_name": fieldName
      }
      console.log("payload", pay_load);
      const deleteResponse = await fetch(
        `${baseUrl}/modules/documents/delete/${delete_id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token
            // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMTMiLCJpYXQiOjE3MTk0MDUwODIsImV4cCI6MTcyMTk5NzA4Mn0.kituNf_d-LrZ-WDZBUuRbWbgNmyJgWUJlzTNgfzKit4",
          },
          body: JSON.stringify(pay_load),
        }
      );
      var response = await deleteResponse.json();
      console.log(response);
      if (response.success) {
        setDocument(null)
        console.log("Delete Response is =>" + JSON.stringify(response));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const hideLightBox = () => {
    setLightBoxDisplay(false)
 }

  return (
    <div className="container inputBox px-0">
      <div className="row">
        <div className="col-lg-6 px-0">
          <div className="">
            <div className="">
              <span className='document_name label'>{id}</span>
            </div>
            {
              status === "STS_SUBMIT" ? null : (
                <>
                  <label htmlFor={`${id} file-upload`} className="custom-file-upload">Upload Document</label>
                  <input
                    type="file"
                    name="document"
                    className=''
                    id={`${id} file-upload`}
                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                    onChange={handleImageSelect}
                  />
                </>
              )
            }
          </div>
          <Message text={error} type={'error'} />
          {
            document && (
              <div className="file_container">
                <span className='document_name label'>{document.name}</span>
                <button className='deleButton' onClick={(e) => { handleToShow(e, document); }}>
                  {previewImage === document.path || previewPdf === document.path ? 'Hide' : 'Preview'}
                </button>
                {
                  status === "STS_SUBMIT" ? null : (
                    <button onClick={() => handleDocumentDelete(document, setDocument)} className='deletePdfButton' type="submit"><i className="fa-solid fa-xmark"></i></button>
                  )
                }
              </div>
            )
          }
          {document && previewImage && (
            <div className="preview-container text-center">
              <img style={{ width: '200px' }} onClick={() => showImage(previewImage)} src={previewImage} alt="Preview" className="preview-image" />
            </div>
          )}

         <div className="lightBox">
         {lightboxDisplay &&
            <div id="lightbox">
              <img className="lightbox-img" onClick={hideLightBox} src={imageToShow}></img>
            </div>
          }
         </div>

          {document && previewPdf && (
            <div className="preview-container">
              <iframe
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${previewPdf}#toolbar=0&scrollbar=0`}
                height="200px"
                width="100%"></iframe>

            </div>
          )}

        </div>
      </div>

      {loading && (
        <div className="loader spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
};

export default FileSelector;
