import React from "react";
import '../../components/registration/registration.css'
import RegistrationEdit from "../../components/registration/RegistrationEdit";
import Header from "../../components/header/Header";
export const RegistrationEditPage = () => {
  return (
    <div className="container-fluid registrationContainer d-flex justify-content-center">
      <section className="awardRegistrationForm">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading">
             <Header/>
              <RegistrationEdit />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
