import './pagination.css'
const Pagination = ({ currentPage, setCurrentPage, totalPage }) => {

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPage.length));
      };
    
      const handlePageChange = (event, page) => {
        setCurrentPage(page);
      };
      const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
      };

    return (
        <>
            <nav aria-label="Page navigation">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button
                            className="page-link"
                            onClick={handlePreviousPage}
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </button>
                    </li>
                    {totalPage &&
                        totalPage.map((number, index) => (
                            <li
                                className={`page-item ${currentPage === number ? 'active' : ''}`}
                                key={index}
                            >
                                <button
                                    className="page-link"
                                    onClick={(e) => handlePageChange(e, number)}
                                >
                                    {number}
                                </button>
                            </li>
                        ))}
                    <li className={`page-item ${currentPage === totalPage?.length ? 'disabled' : ''}`}>
                        <button
                            className="page-link page-font"
                            onClick={handleNextPage}
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </button>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Pagination
