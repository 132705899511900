import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getRegistrationList } from "../../services/RegistrationService";
import Message from "../../constants/Message";
import { useUser } from "../../utils/utils";
import { CountryCode } from "../../constants/countryCode";
import ImageSelector from "../imageSelector/ImageSelector";
import PdfSelector from "../fileSelector/FileSelector";
import { sub_category } from '../../constants/category'

const RegistrationEdit = () => {
  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ address: {}, family_details: {} });
  const [errors, setError] = useState();
  const [candidateData, setCandidateData] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [passportImage, setPassportImage] = useState();
  const [contryList, setCountryList] = useState([]);
  const { user } = useUser();
  const params = useParams();
  var candidate_id = params.candidate_id;
  const [idProof, setIdProof] = useState(null)
  const [document1, setDocument1] = useState(null)
  const [document2, setDocument2] = useState(null)
  const [document3, setDocument3] = useState(null)
  const [document4, setDocument4] = useState(null)
  const [document5, setDocument5] = useState(null)
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  console.log("passportImage", passportImage);
  useEffect(() => {
    // console.log("token in edit page",user?.token);
    // console.log(candidate_id);
    //get candidate from api and set into state
    getCandidate(candidate_id);
  }, []);

  useEffect(() => {
    if (candidateData.category) {
      setSubCategoryOptions(sub_category[candidateData.category] || []);
    }
  }, [candidateData.category]);

  useEffect(() => {
    //console.log("form values - " + JSON.stringify(formValues));
    console.log(candidateData)
  }, [candidateData]);
  useEffect(() => {
    getCountryList();
  }, [])

  useEffect(() => {
    console.log(formValues);
  }, [formValues])

  const handleInputChange = (e) => {
    if (candidateData.status === "STS_SUBMIT") {
      return candidateData
    }
    else {
      const { name, value } = e.target;
      if (name === "category") {
        setSubCategoryOptions(sub_category[value] || []);
        setFormValues({ ...formValues, [name]: value, sub_category: "" });
      } else {
        setFormValues({ ...formValues, [name]: value });
      }
    }

  };

  const handleAddressChange = (e) => {
    if (candidateData.status === "STS_SUBMIT") {
      return candidateData
    }
    else {
      var modified_address = formValues.address;
      modified_address[e.target.name] = e.target.value;
      setFormValues({ ...formValues, ...{ address: modified_address } });
    }
  };

  const handleFamilyDetailsChange = (e) => {
    if (candidateData.status === "STS_SUBMIT") {
      return candidateData
    }
    else {
      var modified_family_details = formValues.family_details;
      modified_family_details[e.target.name] = e.target.value;
      setFormValues({ ...formValues, ...{ family_details: modified_family_details } });
    }

  };

  const onChangePassportImage = (newlySelectedImage) => {
    setPassportImage(newlySelectedImage);
  };

  const getCountryList = async () => {
    try {
      const countryResponse = await fetch(
        `${baseUrl}/modules/countries/get-countries`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token
            // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNTksImlhdCI6MTcxODAyNjg4NywiZXhwIjoxNzIwNjE4ODg3fQ.yKxJXTbPHU-SurNmdPTNz8hkSc_peEmrSVGH0zBVwiU",
          },
        }
      );
      const countryListResponse = await countryResponse.json();
      setCountryList(countryListResponse.records);
      // console.log(contryList);
    } catch (error) {
      console.log("Error" + error);
    }
  };

  //Get canditate data information by canditate id
  const getCandidate = async (candidate_id) => {
    try {
      const getCandidateRespose = await fetch(
        `${baseUrl}/modules/candidates/apply/get/${candidate_id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token
            // token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNTksImlhdCI6MTcxODAyNjg4NywiZXhwIjoxNzIwNjE4ODg3fQ.yKxJXTbPHU-SurNmdPTNz8hkSc_peEmrSVGH0zBVwiU",
          },
        }
      );
      const candidateDataFromAPI = await getCandidateRespose.json();
      setCandidateData(candidateDataFromAPI?.record);
      // setSubCategoryOptions(candidateDataFromAPI?.record?.sub_category)
      setIdProof(candidateDataFromAPI?.record?.id_proof)
      setDocument1(candidateDataFromAPI?.record?.document_1)
      setDocument2(candidateDataFromAPI?.record?.document_2)
      setDocument3(candidateDataFromAPI?.record?.document_3)
      setDocument4(candidateDataFromAPI?.record?.document_4)
      setDocument5(candidateDataFromAPI?.record?.document_5)
    }
    catch (error) {
      console.log("Error" + error)
    }
  };

  const saveCandidate = async (status_role) => {

    const payload = {
      candidate_id: candidate_id,
      ...formValues,
      "is_active": true,
      passport_image: passportImage,
      id_proof: idProof?.document_id,
      document_1: document1?.document_id,
      document_2: document2?.document_id,
      document_3: document3?.document_id,
      document_4: document4?.document_id,
      document_5: document5?.document_id,
      status: status_role
    }
    if (JSON.stringify(payload.family_details) === '{}') delete payload.family_details
    if (JSON.stringify(payload.address) === '{}') delete payload.address

    console.log(payload);
    try {
      const response = await fetch(
        `${baseUrl}/modules/candidates/apply/update`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token
            // token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMTMiLCJpYXQiOjE3MjA0MjY2MzUsImV4cCI6MTcyMzAxODYzNX0.6fp8BV5tlGRYP0Om9uMmkiEyEv-24vTjDzJH2MKgoWg",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      console.log(data)
      if (data.success) {
        navigate("/registered-list");
      }
    } catch (error) {
      console.log("Error  " + error);
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <div>
   
      <div className="my-3">
        <button onClick={()=>navigate('/registered-list')} className="btn btn-primary bg_button">Back</button>
      </div>
      <div className="">
        <label>
          Are you the applicant or nominating another person?{" "}
          <sup className="starOne">*</sup>
        </label>
        <select
          className="form-control mt-1"
          name="candidate_type"
          value={formValues?.candidate_type || candidateData?.candidate_type}
          onChange={handleInputChange}
        >
          <option value="-- Select--">-- Select --</option>
          <option value="CAN_APPLICANT">Applicant</option>
          <option value="CAN_NOMINEE">Nominating another person</option>
        </select>
        <label className="mt-2">
          If you are nominating somebody, please provide the details of the
          nominee and not your details.
        </label>
      </div>
      <div className="registrationDiv">
        <div>
          <label className="mt-">Award Category <sup className="starOne">*</sup></label>
          <select
            className="form-control mt-1"
            name="category"
            value={formValues?.category || candidateData?.category}
            onChange={handleInputChange}
          >
            <option value="" aria-readonly>
              -- Select Award Category --
            </option>
            <option value="AWD_STUDENT">Student Achievers Award</option>
            <option value="AWD_YOUTH">Youth Achievers Award</option>
            <option value="AWD_PROM_YOUTH">Promotion of Youth Services Award</option>
          </select>
          <Message type={"error"} errors={errors} fieldName={"category"} />
        </div>
        <div>
          <label className="mt-">Award Sub Category <sup className="starOne">*</sup></label>
          <select
            className="form-control mt-1"
            name="sub_category"
            value={formValues?.sub_category || candidateData?.sub_category}
            onChange={handleInputChange}
          >
            <option value="" aria-readonly>
              -- Select Sub Award Category --
            </option>
            {subCategoryOptions.map((subCat) => (
              <option key={subCat.value} value={subCat.value}>
                {subCat.label}
              </option>
            ))}
          </select>
          <Message type={"error"} errors={errors} fieldName={"sub_category"} />
        </div>
        <div className="card pt-3">
          <div className="card-body">
            <form>
              <div className="form-group d-flex justify-content-between">
                {/* First Name */}
                <div className="firstName">
                  <label htmlFor="fullName">
                    First Name <sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    value={formValues?.first_name || candidateData?.first_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                {/* Last Name */}
                <div className="lastName">
                  <label htmlFor="fullName">Last Name </label>
                  <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    value={formValues?.last_name || candidateData?.last_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="family_name">
                  <label htmlFor="fullName">
                    Gheru Naav (Sourashtra Family Name)<sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="family_name"
                    value={formValues?.family_name || candidateData?.family_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group d-flex justify-content-between">
                {/* GENDER Type */}
                <div className="gender">
                  <label htmlFor="gender">
                    Gender<sup className="star">*</sup>
                  </label>
                  <select
                    className="form-control"
                    name="gender"
                    value={formValues?.gender || candidateData?.gender}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">-- Select Gender --</option>
                    <option value="GEN_MALE">Male</option>
                    <option value="GEN_FEMALE">Female</option>
                    <option value="GEN_OTHER">Others</option>
                  </select>
                </div>
                {/* Date Of Birth */}
                <div className="dob">
                  <label htmlFor="dob">
                    Date Of Birth<sup className="star">*</sup>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="date_of_birth"
                    value={formValues?.date_of_birth || candidateData?.date_of_birth}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group d-flex justify-content-between">
                {/* Profession Type */}
                <div className="profeesionType">
                  <label htmlFor="Status">
                    Profession Type<sup className="star">*</sup>
                  </label>
                  <select
                    className="form-control"
                    name="profession_type"
                    value={formValues?.profession_type || candidateData?.profession_type}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">-- Select Profession Type --</option>
                    <option value="PROF_STUDENT">Student</option>
                    <option value="PROF_EMPLOYEE">Employee</option>
                    <option value="PROF_PROFESSIONAL">Professional</option>
                    <option value="PROF_SELF_EMPLOYED">Self Employed</option>
                  </select>
                </div>

                <div className="current_occupation">
                  <label htmlFor="fullName">
                    Current Occupation<sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="current_occupation"
                    value={formValues?.current_occupation || candidateData?.current_occupation}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group d-flex justify-content-between">
                {/* Marital status*/}
                <div className="maritalStatus">
                  <label htmlFor="maritalStatus">
                    Marital Status<sup className="star">*</sup>
                  </label>
                  <select
                    className="form-control"
                    name="marital_status"
                    value={formValues?.marital_status || candidateData?.marital_status}
                    onChange={handleInputChange}
                  >
                    <option value="">-- Select Marital Status --</option>
                    <option value="MS_SINGLE">Single</option>
                    <option value="MS_MARRIED">Married</option>
                    <option value="MS_DIVORCED">Divorced</option>
                  </select>
                </div>

                <div className="spouseName">
                  <label htmlFor="fullName">Spouse Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="spouse_name"
                    value={formValues?.spouse_name || candidateData?.spouse_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                {/* Email */}
                <label htmlFor="email">
                  Candidate's Email<sup className="star">*</sup>
                </label>
                <input
                  type="email"
                  className="form-control"
                  onChange={handleInputChange}
                  name="candidate_email"
                  value={formValues?.candidate_email || candidateData.candidate_email}
                  required
                />
                <Message type={"error"} errors={errors} fieldName={"email"} />
              </div>
              <div className="mobileNumberDiv form-group">
                {/* Mobile Number */}
                <label htmlFor="phone">
                  Candidate's Phone<sup className="star">*</sup>
                </label>
                <div className="mobileNumber d-flex">
                  <select
                    id="country-codes"
                    className="countryCode form-control"
                    onChange={handleInputChange}
                    name="candidate_phone_code"
                    value={formValues?.candidate_phone_code || candidateData?.candidate_phone_code}
                  >
                    {CountryCode.map((code) => (
                      <option key={code.dial_code} value={code.dial_code}>
                        {code.name}{code.dial_code}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    className="mobileInput form-control"
                    onChange={handleInputChange}
                    name="candidate_phone"
                    value={formValues?.candidate_phone || candidateData?.candidate_phone}
                    required
                  />
                </div>

                <Message type={"error"} errors={errors} fieldName={"phone"} />
              </div>
              <div className="form-group d-flex flex-column">
                {/* Address Two */}
                <div className="w-100">
                  <label htmlFor="address">
                    Address Line 1<sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="line_1"
                    value={formValues?.address?.line_1 || candidateData?.address?.line_1}
                    onChange={handleAddressChange}
                    required
                  />
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"line_1"}
                  />
                </div>

                {/* Village */}
                <div className="w-100">
                  <label htmlFor="address">Address Line 2</label>
                  <input
                    type="text"
                    className="form-control"
                    name="line_2"
                    value={formValues?.address?.line_2 || candidateData?.address?.line_2}
                    onChange={handleAddressChange}
                    required
                  />
                </div>

                {/* City */}
                <div className="w-100">
                  <label htmlFor="address">
                    City<sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={formValues?.address?.city || candidateData?.address?.city}
                    onChange={handleAddressChange}
                    required
                  />
                  <Message type={"error"} errors={errors} fieldName={"city"} />
                </div>

                {/* State */}
                <div className="w-100">
                  <label htmlFor="address">
                    State <sup className="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    value={formValues?.address?.state || candidateData?.address?.state}
                    onChange={handleAddressChange}
                    required
                  />
                  <Message type={"error"} errors={errors} fieldName={"state"} />
                </div>

                {/* Pincode */}
                <div className="zipcode">
                  <label htmlFor="pincode">
                    Zip or Postal Code <sup className="star">*</sup>
                  </label>
                  <input
                    className="form-control"
                    name="zipcode"
                    value={formValues?.address?.zipcode || candidateData?.address?.zipcode}
                    onChange={handleAddressChange}
                    required
                  />
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"zipcode"}
                  />
                </div>
                {/* Nationality */}
                <div className="w-100">
                  <label htmlFor="address">
                    Country<sup className="star">*</sup>
                  </label>
                  <div className="d-flex">
                    <select
                      id="country-codes"
                      className="form-control"
                      onChange={handleInputChange}
                      name="country"
                      value={formValues?.address?.country || candidateData?.address?.country}
                    >
                      {contryList?.map((country) => (
                        <option value={country.country_code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"country"}
                  />
                </div>
                <div className="form-group">
                  <ImageSelector
                    candidateData={candidateData}
                    imageToShow={passportImage}
                    onChangeImage={onChangePassportImage}
                  />
                </div>
                <div className="form-group">
                  <PdfSelector
                    fieldName={'id_proof'}
                    candidate_id={candidateData?.candidate_id}
                    id={'Aadhar, License, Voter ID, Passport , Student ID, Company ID. any National ID'}
                    document={idProof}
                    setDocument={setIdProof}
                    status={candidateData.status} />

                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card">
          {/* Family Details */}
          <div className="card-header mb-4">
            <h2 className="title">FAMILY DETAILS</h2>
          </div>
          <div className="form-group d-flex justify-content-between">
            {/* Father Name */}
            <div className="fName">
              <label htmlFor="fatherName">
                Father's Name<sup className="star">*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                name="father_name"
                value={formValues?.family_details?.father_name || candidateData?.father_name}
                onChange={handleFamilyDetailsChange}
                required
              />
            </div>

            <div className="mName">
              <label htmlFor="motherName">
                Mother's Name<sup className="star">*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                name="mother_name"
                value={formValues?.family_details?.mother_name || candidateData.mother_name}
                onChange={handleFamilyDetailsChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="card">
          <div className="form-group">
            <label htmlFor="achievements"></label>
            <div className="">
              <p className="pt-2">
                Candidate's Profile In 250 Words<sup className="starOne">*</sup>
              </p>
              <textarea
                className="form-control"
                name="profile_description"
                value={formValues?.profile_description || candidateData?.profile_description}
                onChange={handleInputChange}
                rows="4"
              ></textarea>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="achievements"></label>
            <div className="">
              <p className="pt-2">
                Candidate's Achievement In 500 Words
                <sup className="starOne">*</sup>
              </p>
              <textarea
                className="form-control"
                name="achievements_description"
                onChange={handleInputChange}
                value={formValues?.achievements_description || candidateData.achievements_description}
                rows="4"
              ></textarea>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h2 className="title">Document Evidence</h2>
          </div>
          <div className="form-group">
            <label htmlFor="" className="py-4">
              Please upload marksheets, certificates, paper cuttings, magazine
              excerpts or any other documents to showcase your achievements
            </label>
            <div className="form-group">
              <PdfSelector
                fieldName={'document_1'}
                candidate_id={candidateData?.candidate_id}
                id={"Document 1"}
                document={document1}
                setDocument={setDocument1}
                status={candidateData.status}
              />
              <PdfSelector
                fieldName={'document_2'}
                candidate_id={candidateData?.candidate_id}
                id={"Document 2"}
                document={document2}
                setDocument={setDocument2}
                status={candidateData.status}
              />
              <PdfSelector
                fieldName={'document_3'}
                candidate_id={candidateData?.candidate_id}
                id={"Document 3"}
                document={document3}
                setDocument={setDocument3}
                status={candidateData.status}

              />
              <PdfSelector
                fieldName={'document_4'}
                candidate_id={candidateData?.candidate_id}
                id={"Document 4"}
                document={document4}
                setDocument={setDocument4}
                status={candidateData.status}

              />
              <PdfSelector
                fieldName={'document_5'}
                candidate_id={candidateData?.candidate_id}
                id={"Document 5"}
                document={document5}
                setDocument={setDocument5}
                status={candidateData.status}

              />
            </div>
          </div>
        </div>

        <div className="registrationButtonCard card">
          {/* Save Button */}
          {
            candidateData.status === "STS_DRAFT" ? (
              <>
                <button type="submit" onClick={() => saveCandidate('STS_DRAFT')} className="darftBtn">
                  Save for Future Editing

                </button>
                <button
                  type="submit"
                  onClick={() => saveCandidate('STS_SUBMIT')}
                  className="darftBtn"
                >
                  Submit for Processing
                </button>
              </>
            ) : null
          }
          {/* <div className="loaderfooterDiv">
            <div className="loaderFooter">
              {isLoading && (
                <div className="loader spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RegistrationEdit;
