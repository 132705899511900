// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Error Message */

.message {
    font-size: 13px;
  }
  
  .error{
    color: red;
  }
  .success{
    color: green;
  }
  `, "",{"version":3,"sources":["webpack://./src/constants/errorMessages.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,eAAe;EACjB;;EAEA;IACE,UAAU;EACZ;EACA;IACE,YAAY;EACd","sourcesContent":["/* Error Message */\n\n.message {\n    font-size: 13px;\n  }\n  \n  .error{\n    color: red;\n  }\n  .success{\n    color: green;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
