
import { server } from "./server"

export const getRegistrationList = () =>{
    return server+'/modules/candidates/apply/list'
} 

export const getRegistrationByID = () =>{
    return true
}

export const AddRegistration = () =>{
    return server+'/modules/candidates/apply/register'
}

export const UpdateRegistration = () =>{
    return `${server}/modules/candidates/apply/update`
}

export const DeleteRegistration = () =>{
    return true
}