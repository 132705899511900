// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.login-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-logo{
    margin: 100px 0 10px;
    width: 100px;
}
.spinner-border{
    color: #9a2c23;
}
a{
    text-decoration: none;
}
.login_sub_wrapper{
    width: 50%;
  
}
.login-row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.register-text{
    letter-spacing: 0.5px;
}
@media(max-width:576px){
    .login-logo{
        width: 250px;
        margin-bottom: 30px;
        margin-top: 100px;
    }
    .form_wrapper{
        margin-top: 80px;
    }
    .login_sub_wrapper{
        width: 60%;
    }
    .bvk_logo{
        width: 200px !important;
    }
    
}

@media screen and (max-width:768px) {
    .login_sub_wrapper{
        width: 70%;
      
    }
}

@media screen and (max-width:992px) {
    .login_sub_wrapper{
        width: 80%;
      
    }
}
@media screen and (max-width:1024px) {
    .login_sub_wrapper{
        width: 90%;
      
    }
    .bvk_logo{
        width: 300px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/login/LoginPage.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,oBAAoB;IACpB,YAAY;AAChB;AACA;IACI,cAAc;AAClB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,UAAU;;AAEd;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,qBAAqB;AACzB;AACA;IACI;QACI,YAAY;QACZ,mBAAmB;QACnB,iBAAiB;IACrB;IACA;QACI,gBAAgB;IACpB;IACA;QACI,UAAU;IACd;IACA;QACI,uBAAuB;IAC3B;;AAEJ;;AAEA;IACI;QACI,UAAU;;IAEd;AACJ;;AAEA;IACI;QACI,UAAU;;IAEd;AACJ;AACA;IACI;QACI,UAAU;;IAEd;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":["\n.login-wrapper{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.login-logo{\n    margin: 100px 0 10px;\n    width: 100px;\n}\n.spinner-border{\n    color: #9a2c23;\n}\na{\n    text-decoration: none;\n}\n.login_sub_wrapper{\n    width: 50%;\n  \n}\n.login-row{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.register-text{\n    letter-spacing: 0.5px;\n}\n@media(max-width:576px){\n    .login-logo{\n        width: 250px;\n        margin-bottom: 30px;\n        margin-top: 100px;\n    }\n    .form_wrapper{\n        margin-top: 80px;\n    }\n    .login_sub_wrapper{\n        width: 60%;\n    }\n    .bvk_logo{\n        width: 200px !important;\n    }\n    \n}\n\n@media screen and (max-width:768px) {\n    .login_sub_wrapper{\n        width: 70%;\n      \n    }\n}\n\n@media screen and (max-width:992px) {\n    .login_sub_wrapper{\n        width: 80%;\n      \n    }\n}\n@media screen and (max-width:1024px) {\n    .login_sub_wrapper{\n        width: 90%;\n      \n    }\n    .bvk_logo{\n        width: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
