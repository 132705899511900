import './errorMessages.css'
export const errorMessages = {
    "category_reqd":"Award category is required",
    "name_reqd": "Name is required",
    "first_name_reqd": "First name is required",
    "family_name_reqd": "Family name is required",
    "gender_reqd":"Gender is required",
    "date_of_birth_reqd": "Date of birth is required",
    "profession_type_reqd" :"Profession type is required",
    "current_occupation_reqd":"Current occupation is required",
    "marital_status_reqd":"Marital status is required",
    "candidate_email_reqd":"Candidate email is required",
    "passport_image_reqd":"Passport Image is required",
    "email_reqd":"Email is required",
    "email_does_not_exists":"Email does not exists",
    "profile_description_reqd":"Profile description is required",
    "achievements_description_reqd":"Achievement description is required",
    "email_already_exists":"Email already exists",
    "password_min_1_special":"Password must be have 1 special character",
    "user_not_found":'Candidate not found',
    "phone_already_exists":"Phone number already exists",
    "confirm_password_reqd":"Confirm password required",
    "password_reqd":"Password required",
    "phone_reqd":"Phone number is required",
    "line_1_reqd":"Address is required",
    "city_reqd":"City is required",
    "state_reqd":"State is required",
    "zipcode_reqd":"Zipcode is required",
    "country_reqd": "Country is required",
    'invalid_session_try_reset_again':'Invalid OTP or please enter OTP',
    'password_confirm_mismatch':'Password and confirm password does not match',
    'password_min_1_uppercase':'The password requires at least one uppercase letter',
    "password_invalid" : "Invalid password",
    "otp_code_reqd":"OTP is required",
    "invalid_otp_code_try_reset_again":"Please enter the valid OTP",
    "mother_name_reqd":"Mother Name is required",
    "father_name_reqd":"Father Name is required"
}
