import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Message from "../../constants/Message";
import { useUser } from "../../utils/utils";


const Login = () => {
  const { setUser } = useUser()

  const [formValues, setFormValues] = useState()
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState();

  const navigate = useNavigate()

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      console.log('loading response is ' + loading)
      var payload = { ...formValues }
      console.log("Payload" + JSON.stringify(payload));
      var loginResponse = await fetch(`${baseUrl}/modules/users/login`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload)
        })
      var response = await loginResponse.json()
      console.log("login response1 - " + JSON.stringify(response))
      if (response.success) {
        console.log("setting token into user" + response.token);
        setUser({ token: response.token })
        localStorage.setItem('user', JSON.stringify({ token: response.token }))
        navigate('/registered-list')
        // navigate('/registered-list?token='+response.token)

      }
      else if (response.errors.length > 0) {
        setError(response.errors)
      }
    }

    catch (error) {
      console.log("Error :" + error)
    }
    finally {
      console.log('loading response is before finally ' + loading)
      setLoading(false)
    }
  };

  return (
    <div>
      <form className="mt-4" onSubmit={login}>

        <div className="mb-3">
          <label htmlFor="" className="form-label">
            Email/Phone Number
          </label>
          <input
            type="text"
            className="form-control"
            name="email"
            value={formValues?.email}
            onChange={handleInputChange}
          />
          <Message type={'error'} errors={errors} fieldName={'phone'} />
          <Message type={'error'} errors={errors} fieldName={'user'} />
        </div>
        <div className="mb-3">
          <label htmlFor="" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            name="password"
            value={formValues?.password}
            onChange={handleInputChange}
          />
          <Message type={'error'} errors={errors} fieldName={'password'} />
        </div>

        <div className="text-center">
          {loading && <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>}
        </div>

        <div className="d-grid gap-2">
          <button type="submit" className="btn btn-primary bg_button">
            Login
          </button>
        </div>
        <div className="d-flex justify-content-between gap-2 mt-3">
          <p>Create a new account ? <a href="/signup" className="label">Sign up</a> </p>
          <p><a className="label" href="/forgot-password">Forgot Password</a></p>
        </div>
        <div className="">
          <p className="register-text">If you find it challenging to register/login, <br /> please Click here to <span><a className="label" href="https://forms.gle/8q5hRdWaSyEUK8D26" target="_blank">register</a></span></p>
        </div>
      </form>
    </div>
  );
};

export default Login;
