import "./otp.css";
import React from "react";
export const LoginOTP = () => {
  
  return (
    <div>
      <form>
        <div className="mb-3">
          <div className="d-flex flex-column">
          <label htmlFor="" className="form-label">
            Enter OTP
          </label>
          <small className="mb-2">OTP has been send your mobile number</small>
          <input type="text"  className="form-control" name="password" />
          </div>
        </div>
        <div className="d-grid gap-2">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
