import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";

export const useUser = () => {
    const { user, setUser } = useContext(UserContext);
    // console.log('user token in useUser - ' + user?.token)
        if(!user?.token){
            // console.log("trying to get from localstorage")
            if (typeof window != undefined) {
                // console.log("window object present")
                const storedUser = localStorage.getItem('user');
                if (storedUser) {
                    // console.log("setting token from storage into context")
                    setUser(JSON.parse(storedUser));
                }
            } 
        }
  
    return { user, setUser };
}
