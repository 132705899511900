import React from 'react'
import bvk_logo from '../../images/bvk-new-logo.jpg'
import { useUser } from '../../utils/utils'
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate()

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });
    const { user, setUser } = useUser()

    const handleLogout = async () => {
        const confirmation = await Swal.fire({
            title: "Are you sure to signout ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085D6",
            cancelButtonColor: "#d33",
        });

        if (confirmation.isConfirmed) {
            try {
                if (user?.token) {
                    setUser(null)
                    localStorage.removeItem('user')
                    navigate('/')
                }
                else {
                    Toast.fire({
                        title: "Failed to Logout",
                        icon: "error",
                    })
                }

            } catch (error) {
                Toast.fire({
                    title: "An error occurred while delete the shop",
                    icon: "error",
                });
                console.log(error);
            }
        }
    }
    return (
        <>
            <div className="headerContainer d-flex align-align-items-center justify-content-between px-5 py-3 ">
                <div className="">
                    <img src={bvk_logo} alt="BVK LOGO" className='img-fluid me-2' width={"50px"} style={{ objectFit: "cover",borderRadius:'100px' }} />
                    <span className="navbar-brand title" >BVK AWARDS</span>
                </div>
                <div className="">
                    <ul className=" ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <button onClick={() => handleLogout()} className="btn btn-primary bg_button" aria-current="page">Logout</button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Navbar
