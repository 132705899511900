// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-logo{
    margin: 30px 0 0;
    width: 100px;
    object-fit: cover;
}

@media(max-width: 993px) {
    .formWrapper{
        width: 300px !important;
        padding: 0 !important;
    }
    .login-logo{
        width: 100px;
        /* margin-bottom: 30px; */
        margin-top: 50px;
    }
    .form_wrapper{
        margin-top: 30px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/signup/signup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,uBAAuB;QACvB,qBAAqB;IACzB;IACA;QACI,YAAY;QACZ,yBAAyB;QACzB,gBAAgB;IACpB;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".signup-wrapper{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.login-logo{\n    margin: 30px 0 0;\n    width: 100px;\n    object-fit: cover;\n}\n\n@media(max-width: 993px) {\n    .formWrapper{\n        width: 300px !important;\n        padding: 0 !important;\n    }\n    .login-logo{\n        width: 100px;\n        /* margin-bottom: 30px; */\n        margin-top: 50px;\n    }\n    .form_wrapper{\n        margin-top: 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
