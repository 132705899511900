import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Message from "../../constants/Message";

const ForgotPassword = () => {

    const [formValues, setFormValues] = useState()
    const [loading, setLoading] = useState(false);
    const [errors, setError] = useState();
    const navigate = useNavigate()

    var baseUrl = process.env.REACT_APP_DEV_BASE_URL
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const login = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            console.log('loading response is ' + loading)
            var payload = { ...formValues }
            console.log("Payload" + JSON.stringify(payload));
            var loginResponse = await fetch(`${baseUrl}/modules/users/forgot-password`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload)
                })
            var response = await loginResponse.json()
            console.log("login response1 - " + JSON.stringify(response))
            if (response.success) {
                navigate(`/changepassword-verification/${formValues?.email}`)

            }
            else if (response.errors.length > 0) {
                setError(response.errors)
            }
        }
        catch (error) {
            console.log("Error :" + error)
        }
        finally {
            console.log('loading response is before finally ' + loading)
            setLoading(false)
        }
    };

    return (
            <form className="" onSubmit={login}>
                <div className="mb-3">
                    <label htmlFor="" className="form-label">
                        Email
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        name="email"
                        value={formValues?.email}
                        onChange={handleInputChange}
                    />
                    <Message type={'error'} errors={errors} fieldName={'email'} />
                   
                </div>

                <div className="text-center">
                    {loading && <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>}
                </div>

                <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary bg_button">
                        Submit
                    </button>
                </div>
                <div className="d-grid gap-2 mt-3">
                    <p>Already have an account ? <a href="/" className="label">Login</a> </p>
                </div>
            </form>
    );
};

export default ForgotPassword;
