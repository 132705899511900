import React, { useState,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Message from "../../constants/Message";

export const SignupOTP = () => {
  
  var baseUrl = process.env.REACT_APP_DEV_BASE_URL

  const [formValue, setFormValue] = useState()
  const [errors , setErrors] =useState();
  const navigate =useNavigate()
  const param =useParams();
  const email =param.email;
  
  useEffect(()=>{
    console.log('form Value is '+ JSON.stringify(formValue));
  })

  console.log("email",email);

  const handleInputChange = (e)=>{
    const { name, value} = e.target
     setFormValue({
       ...formValue,
       [name] :value,
     })
   }
   const submit = async(e)=>{
    e.preventDefault();
    try{
      var payload ={
        ...formValue,email
      }
      
        const response = await fetch (`${baseUrl}/modules/users/set-password`,{
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body : JSON.stringify(payload)
        })
        const signUpresponse = await response.json()
        console.log("response",signUpresponse);
        if(signUpresponse.success){
          // navigate('/registered-list')
          navigate('/')
        }
        else 
          console.log("Error is "+ JSON.stringify(errors))
          setErrors(signUpresponse.errors)
          console.log("Error is "+ JSON.stringify(errors))
        
    }
    catch(error){
      console.log("Error "+error)
    }

   }
  return (
    <div>
      <form onSubmit={submit}>
        <div className="mb-3">
          <div className="d-flex flex-column">
            <small className="mb-2">An OTP has been sent to {email}</small>
            <div className="">
            <label>Registered Email ID</label>
            <input type="text" className="form-control" name="email" value={email} readOnly/>
            </div>
            <label>Enter Your OTP</label>
            <input type="text" className="form-control" name="otp_code" value={formValue?.otp_code || ''} onChange={handleInputChange} />
            <Message type={'error'} errors={errors} fieldName={'otp_code'} />
          </div>
          <div className="d-flex flex-column">
            <label>Enter Your Password</label>
            <input type="password" className="form-control" name="password" value={formValue?.password || ''} onChange={handleInputChange} />
            <Message type={'error'} errors={errors} fieldName={'password'} />
          </div>
          <div className="d-flex flex-column">
            <label>Enter Your Confirm Password</label>
            <input type="password" className="form-control" name="confirm_password" value={formValue?.confirm_password || ''} onChange={handleInputChange} />
            <Message type={'error'} errors={errors} fieldName={'confirm_password'} />
          </div>
        </div>
        <div className="d-grid gap-2">
          <button type="submit" className="btn btn-primary bg_button">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
