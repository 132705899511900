import React, { useEffect, useState } from 'react'
import Message from '../../constants/Message';
import BVK from '../../images/profileBVK.jpg'
import { useUser } from '../../utils/utils';

const ImageSelector = ({ onChangeImage, candidateData }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const [imageToShow, setImageToShow] = useState([])
    const { user, setUser } = useUser()


    useEffect(() => {
        if (selectedFile) {
            uploadImage();
        }
    }, [selectedFile]);

    const handleImageSelect = (event) => {

        const imageToUpload = event.target.files[0];
        validateAndSetFile(imageToUpload);
    };

    const validateAndSetFile = (imageToUpload) => {
        console.log(imageToUpload.size);
        var FILE_SIZE = imageToUpload.size
        var MAX_SIZE = 10000000
        if (imageToUpload) {
            const fileName = imageToUpload.name?.toLowerCase();
            if (
                FILE_SIZE <= MAX_SIZE &&
                (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png') || fileName.endsWith('.heic'))
            ) {
                setSelectedFile(imageToUpload);
                setError('')
            } else {
                onChangeImage(null)
                console.error("File is too large or not a supported format");
                setError('File is too large or not a supported format')
                setSelectedFile(null);
            }
        }
    };

    const uploadImage = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('uploaded_file', selectedFile);
            const headers = {
                token: user?.token
                // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNTksImlhdCI6MTcxODAyNjg4NywiZXhwIjoxNzIwNjE4ODg3fQ.yKxJXTbPHU-SurNmdPTNz8hkSc_peEmrSVGH0zBVwiU",
            };
            var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
            const response = await fetch(`${baseUrl}/modules/documents/upload`, {
                method: "POST",
                headers: headers,
                body: formData,
            });
            if (response.ok) {
                const uploadedImage = await response.json();
                console.log("Upload Successful:", JSON.stringify(uploadedImage));
                onChangeImage(uploadedImage?.document_id)
                setImageToShow(uploadedImage)
            } else {
                console.error("Upload Failed:", response.statusText);
            }
        } catch (error) {
            console.error("Upload Failed:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="container px-0">
                <div className="row">
                    <div className="col-lg-6 px-0">
                        <label htmlFor="certificates">Recent Photo<sup className="star">*</sup></label>
                        <div className="custom-file-input">

                            {
                                candidateData?.status === "STS_SUBMIT" ? null : (
                                    <>
                                        <label className="custom-file-label" htmlFor="certificates">
                                            Choose file
                                        </label>
                                        <input
                                            type="file"
                                            name="uploaded_file"
                                            accept=".jpg, .jpeg, .png, .heic"
                                            onChange={handleImageSelect}
                                        />
                                    </>

                                )
                            }
                            <span className='file_span'>File should be less than 10MB and in .jpg, .jpeg, or .png format</span>
                        </div>
                        <Message text={error} type={'error'} />
                    </div>
                    <div className="col-lg-6">
                        <div className="text-center">
                            {
                                (<img src={imageToShow?.path || candidateData?.passport_image?.path || BVK} className='img-fluid passport_size' alt="passport size image" />)
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                loading && (
                    <div className="loader spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                )
            }
        </>
    )
}

export default ImageSelector
